import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
Alpine.plugin(intersect)
Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()

import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});

console.log("loaded")